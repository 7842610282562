<template>
  <div></div>
</template>

<script>
export default {
  name: "Index",
  created() {
  },
  mounted() {

    // const path = this.$route.path
    const agent = navigator.userAgent
    if (agent.match(/(iPhone|iPod)/i) || agent.toLowerCase().includes("android")) {
      console.log('go MobileWelcome')
      this.$router.push({
        name: 'MobileWelcome'
      })
      // this.$router.push(path.replace('desktop', 'mobile'))
    } else {
      console.log('go DesktopWelcome')
      this.$router.push({
        name: 'DesktopWelcome'
      })
      // this.$router.push(path.replace('mobile', 'desktop'))
    }
  }
}
</script>

<style scoped>

</style>
